import './app.scss';
import domready from 'domready';
import 'lazysizes';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { EventScroller } from './utilities/js/event-scroller';
import { ResizeHandler } from './utilities/js/resize-handler';
import { bindTrackingClick } from './utilities/js/tracking';
import 'friendly-challenge/widget';

// GLOBAL OPTIONS
window.OPTIONS = { // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1280,
        xxl: 1440,
        xxxl: 1920
    },
    colors: {
        nkl: '#24942d',
        nklhover: '#1a6e21',
        skl: '#d11f27',
        sklhover: '#a91920'
    },
    loader: '<b class="loading"><b class="loader icon-refresh"><i>loading...</i></b></b>'
};

const browserCheck = () => {
    window.browser = {
        csshover: !window.matchMedia('(pointer:coarse)').matches,
        ios: (/iPhone|iPad|iPod/i).test(navigator.userAgent),
        iphone: (/iPhone|iPod/i).test(navigator.userAgent),
        android: (/(android)/i).test(navigator.userAgent),
        firefox: (/firefox/i).test(navigator.userAgent),
        safari: (/Version\/[\d.]+.*Safari/).test(navigator.userAgent)
    };

    Object.entries(window.browser).forEach(([key, value]) => {
        document.documentElement.classList.add((!value) ? `no-${key}` : `${key}`);
    });
};

const initContent = ($content) => {
    console.log('start app');
    bindTrackingClick($content);

    // Set NKL or SKL Colors
    const lotteryColor = document.body.getAttribute('data-lottery-type');
    document.documentElement.style.setProperty('--lottery-bg-color', window.OPTIONS.colors[lotteryColor]);
    document.documentElement.style.setProperty('--lottery-bg-color-hover', window.OPTIONS.colors[lotteryColor + 'hover']);

    loadLazyImages();

    // Mein Konto Tab Navigation (Mobile)
    const $tabNavigation = $content.querySelector('[data-tab-nav="root"]');
    if ($tabNavigation) {
        import(/* webpackChunkName: 'tab-navigation' */ './pages/account/tab-navigation').then(({ TabNavigation }) => {
            const $tabNav = new TabNavigation($tabNavigation);
            $tabNav.initialize();
        });
    }

    // Anchors Scroll
    /* const $anchors = $content.querySelectorAll('[data-scroll-to]');
     if ($anchors) {
         import('./utilities/js/anchor.js').then(({ Anchor }) => {
             const Anchors = new Anchor($anchors);
             Anchors.initialize();
         });
     } */

    // Custom Select Options
    const $choices = $content.querySelectorAll('[data-choices]');
    for (let i = 0; i < $choices.length; i++) {
        import('./layout/form/form-choices.js').then(({ FormChoice }) => {
            const $choice = new FormChoice($choices[i]);
            $choice.initialize();
            $choices[i].choices = $choice;
        });
    }

    const $ajaxActions = document.querySelector('[data-ajax-actions="root"]');
    if ($ajaxActions) {
        import(/* webpackChunkName: 'load-components' */ './utilities/js/load-components').then(({ LoadComponents }) => {
            const load = new LoadComponents($ajaxActions);
            load.initialize();
        });
    }
};

/**
 * starting point
 */

domready(() => {
    const initApplication = () => {
        browserCheck();

        window.resizeHandler = new ResizeHandler();
        window.resizeHandler.init();
        window.eventScroller = new EventScroller();
        window.eventScroller.init();

        initContent(document);

        const contentUpdateEvent = new CustomEvent('content.loaded', {
            view: window,
            bubbles: true,
            cancelable: false,
            detail: {
                $context: document
            }
        });

        window.dispatchEvent(contentUpdateEvent);
    };

    initApplication();
});
